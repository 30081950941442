import React, { Dispatch, SetStateAction, useState } from "react";
import QrReader from "react-qr-reader";
import { BsQrCodeScan } from "react-icons/bs";

function QRCodeScanner(props: {
  setSessionURI: Dispatch<SetStateAction<string>>;
  startScan: boolean;
  setStartScan: Dispatch<SetStateAction<boolean>>;
}) {

  const [loadingScan, setLoadingScan] = useState(false);
  // const [data, setData] = useState("");

  const handleScan = async (scanData: any) => {
    setLoadingScan(true);
    if (scanData && scanData !== "") {
      console.log(`loaded >>>`, scanData);
      props.setSessionURI(scanData);
      props.setStartScan(false);
      setLoadingScan(false);
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  return (
    <div className="flex flex-col space-y-4 items-center pt-4 space-x-2 border-t border-gray-600 rounded-b justify-center mx-6">
      <button
        className="text-white focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-[#24b7c3] hover:bg-[#10767f]"
        onClick={() => {
          props.setStartScan(!props.startScan);
        }}
      >
        {props.startScan ? (
          "Taramayı Durdur"
        ) : (
          <div className="flex flex-row space-x-2">
            <BsQrCodeScan size="1.1rem" />
            <span>QR Kod Tara</span>
          </div>
        )}
      </button>
      {props.startScan && (
        <div className="rounded-b">
          <QrReader
            onError={handleError}
            onScan={handleScan}
            // chooseDeviceId={()=>selected}
            style={{ width: "300px" }}
          />
        </div>
      )}
    </div>
  );
}

export default QRCodeScanner;
