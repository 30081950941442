import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MdContentCopy, MdDone } from "react-icons/md";
import WalletManager, { Errors } from "../lib/wallet-manager";

function PasswordPage(props: {
  manager: WalletManager;
  setRedirect: Dispatch<SetStateAction<boolean>>;
  index: number;
}) {
  const handlePassword = async (password: string) => {
    try {
      const valid = await props.manager.privateKeyEnsurePassword(
        password,
        props.index
      );

      if (valid) {
        props.setRedirect(true);
      } else {
        throw Errors.wrongPassword;
      }
    } catch (e: any) {
      console.log(e);
      setError(e.message);
    }
  };

  const [password, setPassword] = useState("");
  const [error, setError] = useState();

  return (
    <div>
      <div className="px-6 pt-10 pb-8 space-y-6">
      <h3 className="text-xl font-medium text-white text-center">
          <p>Cüzdan Parolanızı Giriniz</p>
        </h3>
        {error ? <p className="text-red-500 text-md text-center font-bold">
          {error}
        </p> : null}
        <input
          type="password"
          className="bg-gray-600 border border-gray-600 text-white gsm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
          maxLength={32}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        ></input>
      </div>
      <div className="flex items-center p-4 space-x-2 border-t border-gray-600 rounded-b justify-center mx-6">
        <button
          onClick={async () => await handlePassword(password)}
          data-modal-hide="small-modal"
          type="button"
          className="text-white focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-[#24b7c3] hover:bg-[#10767f]"
        >
          Mnemonic Key Göster
        </button>
      </div>
    </div>
  );
}

export function MnemonicKeyPage(props: { manager: WalletManager; index: number}) {
  const [mnemonicKey, setMnemonicKey] = useState<string | undefined>("");
  const [tick, setTick] = useState(false);

  useEffect(() => {
    const showMnemonicKey = async () => {
      try {
        await props.manager.decrypt(props.index);
        const key = await props.manager.getMnemonicKey(props.index);
        setMnemonicKey(key);
      } catch (e) {
        console.log(e);
      }
    };
    showMnemonicKey();
  }, []); //? Bos dependency array ?

  return (
    <div>
      <div className="px-8 pt-10 pb-8 space-y-6">
        <h3 className="text-xl font-medium text-white text-center">
          <p>Mnemonic Anahtar Kelime Diziniz</p>
        </h3>
         <p className="text-base text-center leading-relaxed text-gray-200 text-xs font-light break-words">
            Aşağıda gösterilen kelimeler, cüzdanınızı kaybettiğinizde kurtarmak için girmeniz gerekecek olan anahtar kelime dizisidir. Bu kelimeleri kesinlikle kimseyle paylaşmamanızı mümkünse de bir kağıt üzerinde yazıp saklamanızı öneririz.
        </p>

        <div className="border-2 border-[#24b7c3] bg-gray-900 p-4 rounded-lg text-[#24b7c3]">
          <p className="text-base text-center break-words">
            {mnemonicKey}
          </p>
        </div>
      </div>

      <div
        onClick={() => {
          navigator.clipboard.writeText(mnemonicKey!);
          setTick(true);
          setTimeout(function () {
            setTick(false);
          }, 4000);
        }}
        className="flex items-center p-4 space-x-2 border-t border-gray-600 rounded-b justify-center mx-8"
      >
        <button className="flex space-x-3 text-sm text-center text-white font-medium rounded-lg px-5 py-2.5 bg-gray-500 hover:bg-gray-800">
          <p>Mnemonic'i Kopyala</p>
          {!tick && <MdContentCopy size="1.2rem" />}
          {tick && <MdDone size="1.2rem" className="text-green-400" />}
        </button>
      </div>
    </div>
  );
}

export default function MnemonicKeyModal(props: {
  visible: boolean;
  onClose: any;
  manager: WalletManager;
  index: number;
}) {
  const [error, setError] = useState();
  const [redirect, setRedirect] = useState(false);

  if (!props.visible) return null;

  return (
    <div className="fixed inset-0 bg-opacity-30 backdrop-blur-sm flex justify-center items-center p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
      <div className="relative w-full max-w-md h-auto bg-gray-700 rounded-lg shadow-2xl shadow-[#24b7c3]/40">
        <div className="relative">
          <button
            type="button"
            onClick={() => {
              props.onClose();
              setError(undefined);
              setRedirect(false);
            }}
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
            data-modal-hide="popup-modal"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
          {!redirect ? (
            <PasswordPage
              manager={props.manager}
              setRedirect={setRedirect}
              index={props.index}
            />
          ) : (
            <MnemonicKeyPage manager={props.manager} index={props.index}/>
          )}
        </div>
      </div>
  );
}
