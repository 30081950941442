import React, { Dispatch, SetStateAction, useState } from "react";
import wc from "../assets/wc.svg";
import { WCFacade } from "../lib/walletconnect-utils";
import QRCodeScanner from "./QRCodeScanner";
import SessionProposalModal from "./SessionProposalModal";

export interface ProposalData {
  name: string;
  url: string;
  icons: Array<string>;
  events: Array<string>;
  methods: Array<string>;
}

export default function WalletConnectModal(props: {
  visible: boolean;
  onClose: any;
  setDappName: Dispatch<SetStateAction<string | undefined>>;
}) {
  const [startScan, setStartScan] = useState<boolean>(false);
  const [sessionURI, setSessionURI] = useState<string>("");
  const [showSpModal, setShowSpModal] = useState<boolean>(false);
  // const [isApproved, setIsApproved] = useState<boolean>(false);
  const [proposalData, setProposalData] = useState<ProposalData | undefined>(
    undefined
  );
  const [error, setError] = useState<string | undefined>();
  const handlePair = async () => {
    try {
      console.log(sessionURI);
      await WCFacade.pair(
        sessionURI,
        setProposalData,
        setShowSpModal,
        setError
      );
    } catch (e: any) {
      console.log(e.message);
    }
  };

  const handleOnClose = () => {
    setShowSpModal(false);
  };

  // const handleSession = () => {};

  if (!props.visible) return null;

  return (
    <div className="fixed inset-0 bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className="relative flex justify-center items-center w-full max-w-md h-full mx-4 md:mx-0">
        <div className="relative bg-gray-700 rounded-lg shadow-2xl shadow-[#24b7c3]/40 w-full">
          <div className="flex items-center justify-between px-5 border-gray-500">
            <img src={wc} className="h-16 w-40" />
            <button
              type="button"
              onClick={() => {
                props.onClose();
                setStartScan(false);
                setError(undefined);
              }}
              className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
              data-modal-hide="small-modal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="px-3 pb-1">
            <QRCodeScanner
              setSessionURI={setSessionURI}
              startScan={startScan}
              setStartScan={setStartScan}
            />

            <div className="flex items-center p-2 space-x-2 justify-center mx-6 text-xs text-gray-400 text-white">
              <p>ya da URI giriniz</p>
            </div>

            <div className="px-5">
              <label
                htmlFor="password"
                className="block mb-2 text-xs font-medium text-white"
              >
                Oturum URI:
              </label>
              <input
                value={sessionURI}
                type="text"
                className="bg-gray-600 border border-gray-600 text-white gsm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                onChange={(e) => {
                  setSessionURI(e.target.value);
                }}
              ></input>
            </div>

            {error && (
              <span
                className={
                  "text-center text-red-500 font-semibold flex items-center justify-center pt-2"
                }
              >
                {error}
              </span>
            )}

            <div className="flex items-center p-4 space-x-2 border-gray-600 rounded-b justify-center mx-6">
              <button
                onClick={async () => {
                  await handlePair();
                  if (proposalData) {
                    setShowSpModal(true);
                    setError(undefined);
                  } else {
                    setShowSpModal(false);
                  }
                  console.log("sonra olması lazım");
                }}
                data-modal-hide="small-modal"
                type="button"
                className="text-white focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-[#24b7c3] hover:bg-[#10767f]"
              >
                BAĞLA
              </button>
            </div>



          </div>
        </div>
      </div>

      <SessionProposalModal
        onClose={handleOnClose}
        visible={showSpModal}
        proposalData={proposalData}
        setDappName={props.setDappName}
        parentOnClose={props.onClose}
      />
    </div>
  );
}
