import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Import from "./components/ImportWallet";
import { useGlobalState } from "./auth";

export default function App() {
  const isRegistered = localStorage.getItem("wallet");
  const session = sessionStorage.getItem("token");
  const authState = useGlobalState("isLogged")[0];
  //console.log(authState);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            authState || session ? (
              <Home />
            ) : isRegistered ? (
              <Login />
            ) : (
              <Register />
            )
          }
        ></Route>
        <Route path="/import"
        element={
          authState || session ? (
            <Home />
          ) : (
          <Import />)
          }>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
