import { TransData } from "../lib/wallet-manager";

export default function TxLogModal(props: {
  visible: boolean;
  transIdx: number;
  onClose: any;
  transactions: Array<TransData>;
}) {
  if (!props.visible) return null;

  return (
    <div className="fixed inset-0 bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className="relative flex justify-center items-center w-full max-w-lg h-full mx-4 md:mx-0">
        <div className="relative bg-gray-700 rounded-lg shadow-2xl shadow-[#24b7c3]/40 w-full">
          <div className="flex items-center justify-between p-6 border-gray-500 rounded-t">
            <h3 className="text-xl font-medium text-white ">İşlem Detayı</h3>
            <button
              type="button"
              onClick={() => {
                props.onClose();
              }}
              className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
              data-modal-hide="small-modal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="space-y-4 pb-6 px-6">
            <div>
              <p className="text-base leading-relaxed text-gray-200 break-words text-sm">
                <span className="font-extrabold">Kimden: </span>
                {[...props.transactions].reverse()[props.transIdx].from}
              </p>
            </div>

            <div>
              <p className="text-base leading-relaxed text-gray-200 break-words text-sm">
                <span className="font-extrabold">Kime: </span>
                {[...props.transactions].reverse()[props.transIdx].to}
              </p>
            </div>

            <div>
              <p className="text-base leading-relaxed text-gray-200 break-words text-sm">
                <span className="font-extrabold">Tutar: </span>
                {[...props.transactions].reverse()[props.transIdx].amount} ETH
              </p>
            </div>

            <div>
              <p className="text-base leading-relaxed text-gray-200 break-words text-sm">
                <span className="font-extrabold">Kullanılan Birim Gaz: </span>
                {parseInt(
                  [...props.transactions].reverse()[props.transIdx].gasUsed,
                  16
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
