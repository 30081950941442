import { createGlobalState } from "react-hooks-global-state";

// Burası App.tsx içindeki routerda koşullu render için kullanıcıyı home page'e yönlendirecek global state

const { setGlobalState, useGlobalState } = createGlobalState({
  isLogged: false,
  showWelcomeModal: false
});

export { useGlobalState, setGlobalState };
