/**
 * bkz. https://docs.walletconnect.com/2.0/specs/clients/sign/client-api
 */

import SignClient from "@walletconnect/sign-client";
import { SessionTypes } from "@walletconnect/types";
import getWalletManager from "../utils/wallet-manager-init";
import { Dispatch, SetStateAction } from "react";
import { ProposalData } from "../components/WalletConnectModal";
import { getSdkError } from '@walletconnect/utils';

// TODO envvar
let projectId = "d632fc4c8784787b38046229cb9c2946";

// TODO envvar
let relayerRegionURL: string = "wss://relay.walletconnect.com";

//? burada var tanimlamak yerine direkt object prototype icinde tanimlamanin
//? bir yolu var midi acaba
let signClient: SignClient | undefined;

const getAccounts = () => {
  let manager = getWalletManager();
  let wallets = manager.getData();
  let accounts: Array<string> = [];
  let i = 0;
  // eslint diyor ki expects a return value from arrow function. Cunku immutable context'de data mutate etmek
  // kafa karisikligina yol acabilir
  wallets.map((wallet) => {
    accounts[i] = "eip155:12345:" + wallet.address;
    i++;
  });
  return accounts;
};

let WCFacade = {
  signClient: signClient,
  id: 0,
  proposalParams: {} as any,
  init: function () {
    SignClient.init({
      logger: "debug",
      projectId: projectId,
      relayUrl: relayerRegionURL ?? process.env.NEXT_PUBLIC_RELAY_URL,
      metadata: {
        name: "WalletConnect pg wallet",
        description: "WalletConnect pg wallet",
        url: "https://walletconnect.com/",
        icons: ["https://avatars.githubusercontent.com/u/37784886"],
      },
    }).then((client) => {
      console.log("Sign client initted");
      this.signClient = client;

      // TODO eventleri dinle
      // this.signClient.on('session_event')
      console.log(this.signClient);
    });
  },

  pair: async function (
    uri: string,
    setProposalData: Dispatch<SetStateAction<ProposalData | undefined>>,
    setShowSpModal: Dispatch<SetStateAction<boolean>>,
    setError: Dispatch<SetStateAction<string | undefined>>
  ) {
    if (!this.signClient) throw new Error("Sign client undefined");

    //! https://docs.walletconnect.com/2.0/javascript/sign/wallet-usage#pairing-with-uri

    // this.signClient
    //   .pair({
    //     uri: uri,
    //   })
    //   .then((pairing) => {
    //     console.log('Sign client paired');
    //     console.log(pairing);
    //     this.id = Number(pairing.expiry);
    //   });

    this.signClient.core.pairing
      .pair({ uri })
      .then((pairing: any) => {
        // console.log(pairing);
      })
      .catch((e) => {
        setError("Eşleşme Sağlanamadı.");
      });

    //! Approve burada yapilabilir
    this.signClient.on("session_proposal", (proposal: any) => {
      console.log("proposal");
      console.log(proposal.id);
      localStorage.setItem("prop_id", proposal.id);
      console.log(proposal.params);
      this.proposalParams = proposal.params;
      setProposalData((proposalData) => ({
        ...proposalData,
        name: proposal.params.proposer.metadata.name,
        url: proposal.params.proposer.metadata.url,
        icons: proposal.params.proposer.metadata.icons,
        events: proposal.params.requiredNamespaces.eip155.events,
        methods: proposal.params.requiredNamespaces.eip155.methods,
      }));
      setShowSpModal(true);
    });

    // console.log(this.signClient.core.pairing.pairings.getAll());

    // console.log('history');
    // console.log(this.signClient.core.history.values);
  },

  approve: function () {
    if (!this.signClient) throw new Error("Sign client undefined");

    let accounts = getAccounts();

    let namespaces: SessionTypes.Namespaces = {
      eip155: {
        methods: ["eth_sign"],
        chains: ["eip155:1", "eip155:12345"],
        events: ["accountsChanged", "connect", "disconnect"],
        accounts: accounts,
        // Uncaught (in promise) Error: Unsupported accounts. approve() namespace,
        //  account 0xDE66CD67c16332f5aa72f1009F0eB93D82754155 should be a string and conform to
        // "namespace:chainId:address" format
      },
    };

    console.log("history");
    console.log(this.id);
    console.log(this.signClient.core.history);
    let prop_id = parseInt(localStorage.getItem("prop_id")!);
    let approved = false; // Bir sekilde global olmadi ise kullanilmiyor
    this.signClient
      .approve({
        id: prop_id, //! Burada kaldim bu id'yi nasil cekecegim
        namespaces: namespaces,
        relayProtocol: "irn",
      })
      .then(({ topic, acknowledged }: { topic: any; acknowledged: any }) => {
        console.log("Sign client approved");
        console.log(topic);
        approved = true;
      })
      .catch((error) => {
        console.log("Sign client approval failed.");
      });
  },
};

export { WCFacade };
