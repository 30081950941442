import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MdContentCopy, MdDone } from "react-icons/md";
import WalletManager, { Errors } from "../lib/wallet-manager";

function NamePage(props: {
    manager: WalletManager;
    setRedirect: Dispatch<SetStateAction<boolean>>;
    index: number;
    setWallets: Dispatch<
        SetStateAction<{ name: string, address: string; encrypted: string }[]>
    >;
    wallets: { name: string, address: string; encrypted: string }[];
}) {


    const [name, setName] = useState<string>("Hesap " + (props.wallets.length + 1));
    const [error, setError] = useState();


    const handleCreateAccount = async (name: string) => {
        try {
            if (name.length !== 0) {
                await props.manager.decrypt(0);
                await props.manager.create(true, name);
                props.setWallets(props.manager.getData());
                props.setRedirect(true);
            } else {
                throw Errors.emptyName;
            }
        } catch (e: any) {
            console.log(e);
            setError(e.message);
        }
    };


    return (
        <div>
            <div className="px-6 pt-10 pb-8 space-y-6">
                <h3 className="text-xl font-medium text-white text-center">
                    <p>Yeni Hesap Ekleme</p>
                </h3>
                <p className="text-center text-white font-light text-sm">
                    Yeni hesabınıza isim verebilir ya da varsayılan ismi kullanabilirsiniz.
                </p>
                <p className="text-red-500 text-sm text-center font-bold">
                    {error && <span>{error}</span>}
                </p>

                <input
                    type="text"
                    value={name}
                    className="bg-gray-600 border border-gray-600 text-white text-center gsm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    maxLength={12}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                ></input>
            </div>
            <div className="flex items-center p-4 space-x-2 border-t border-gray-600 rounded-b justify-center mx-6">
                <button
                    onClick={async () => await handleCreateAccount(name)}
                    data-modal-hide="small-modal"
                    type="button"
                    className="text-white focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-[#24b7c3] hover:bg-[#10767f]"
                >
                    + Hesap Oluştur
                </button>
            </div>
        </div>
    );
}

function NotificationPage() {
    return (
        <div className="px-6 pt-10 pb-8 space-y-6">
            <h3 className="flex justify-center space-x-2 text-xl font-medium text-green-400 text-center">
                <p>Hesabınız Oluşturuldu</p><MdDone size='1.5rem' />
            </h3>
            <p className="text-base text-center leading-relaxed text-gray-200 text-sm font-light break-words">
                Hesabınız başarı ile oluşturuldu. Güvenliğiniz için oluşturulan hesabı seçip menüdeki <span className="font-bold">Özel Anahtarı Getir</span> kısmına gitmenizi ve anahtarınızı görüntülemenizi öneririz.
            </p>
        </div>
    );
}

export default function NewAccountModal(props: {
    visible: boolean;
    onClose: any;
    manager: WalletManager;
    index: number;
    setWallets: Dispatch<
        SetStateAction<{ name: string, address: string; encrypted: string }[]>
    >;
    wallets: { name: string, address: string; encrypted: string }[];
}) {
    const [error, setError] = useState();
    const [redirect, setRedirect] = useState(false);

    if (!props.visible) return null;


    return (
        <div className="fixed inset-0 bg-opacity-30 backdrop-blur-sm flex justify-center items-center p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
            <div className="relative w-full max-w-md h-auto bg-gray-700 rounded-lg shadow-2xl shadow-[#24b7c3]/40">
                <div className="relative">
                    <button
                        type="button"
                        onClick={() => {
                            props.onClose();
                            setError(undefined);
                            setRedirect(false);
                        }}
                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
                        data-modal-hide="popup-modal"
                    >
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>

                {!redirect ? (
                    <NamePage
                        manager={props.manager}
                        setRedirect={setRedirect}
                        index={props.index}
                        setWallets={props.setWallets}
                        wallets={props.wallets}
                    />
                ) : (
                    <NotificationPage />
                )}
            </div>
        </div>
    );
}
