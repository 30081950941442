import LocalStorage from "../wallet-storage/local-storage";
import WalletAccount from "../lib/wallet-account";
import WalletManager from "../lib/wallet-manager";

// WalletManager classının getData() metodundan dönecek olan verinin tipi

export interface WalletData {
  name:string,
  address: string;
  encrypted: string;
}
export interface WalletState {
  wallets: Array<WalletData>;
}

// Bir wallet manager instance'ı initialize edilip bu objeyi döndüren fonksiyon export edildi

const manager = new WalletManager(WalletAccount, new LocalStorage());

manager.loadFromStorage();

const getWalletManager = () => {
  return manager;
};

export default getWalletManager;
