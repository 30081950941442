import React, { Dispatch, SetStateAction } from "react";
import { WCFacade } from "../lib/walletconnect-utils";
import { ProposalData } from "./WalletConnectModal";

export default function SessionProposalModal(props: {
  visible: boolean;
  onClose: any;
  proposalData: ProposalData | undefined;
  setDappName: Dispatch<SetStateAction<string | undefined>>;
  parentOnClose: any;
}) {
  const handleApprove = () => {
    WCFacade.approve();
    props.onClose();
    props.parentOnClose();
  };

  const handleReject = () => {
    props.onClose();
  };

  if (!props.visible) return null;

  return (
    <div className="fixed inset-0 bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className="relative flex justify-center items-center w-full max-w-md h-full mx-4 md:mx-0">
        <div className="relative bg-gray-900 rounded-lg shadow w-full">
          <div className="flex items-center justify-between px-5 py-4 border-gray-500">
            <h3 className="text-xl font-medium text-white">Oturum Teklifi</h3>
            <button
              type="button"
              onClick={() => {
                props.onClose();
              }}
              className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
              data-modal-hide="small-modal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="px-3 pb-1 text-gray-200 border-t border-gray-600 mx-6 space-y-2">
            <div className="flex items-center pt-5 border-gray-600 rounded-b justify-center mx-6">
              <img className="w-28" src={props.proposalData!.icons[0]} />
            </div>
            <div className="flex flex-col items-center p-2 space-x-2 border-gray-600 rounded-b justify-center ">
              <h3 className="text-lg text-center leading-relaxed text-gray-200 break-words">
                {props.proposalData!.name}
              </h3>
              <p className="text-sm text-center leading-relaxed text-gray-200 break-words">
                {props.proposalData!.url}
              </p>
            </div>
            <div className="space-y-1">
              <p className="text-left text-md">Metodlar</p>
              <div className="space-x-2 leading-relaxed text-sm text-center text-gray-200 break-words font-light rounded-lg bg-gray-700 p-4">
                {props.proposalData!.methods.map((method) => {
                  return <span>{"( " + method + " )"}</span>;
                })}
              </div>
            </div>

            <div className="space-y-1">
              <p className="text-left text-md">Eventler</p>
              <div className="text-base space-x-2 text-sm text-center leading-relaxed text-gray-200 break-all rounded-lg bg-gray-700 p-4">
                {props.proposalData!.events.map((evnt) => {
                  return <span>{"( " + evnt + " )"}</span>;
                })}
              </div>
            </div>

            <div className="flex flex-row items-center p-4 space-x-2 border-gray-600 rounded-b justify-center mx-6">
              <button
                onClick={handleReject}
                data-modal-hide="small-modal"
                type="button"
                className="text-red-500 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center border-2 border-red-500 bg-gray-800 hover:bg-red-800"
              >
                Reddet
              </button>

              <button
                onClick={handleApprove}
                data-modal-hide="small-modal"
                type="button"
                className="text-green-500 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center border-2 border-green-500 bg-gray-800 hover:bg-green-800"
              >
                Onayla
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
