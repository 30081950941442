import { Dispatch, SetStateAction, useEffect, useState } from "react";
import WalletManager from "../lib/wallet-manager";
import { WalletData } from "../utils/wallet-manager-init";

function AccountItem(props: {
  index: number;
  walletItem: WalletData;
  manager: WalletManager;
  setAccount: Dispatch<SetStateAction<string>>;
  setIdx: Dispatch<SetStateAction<number>>;
  isActive: boolean;
  setDropdown: Dispatch<SetStateAction<boolean>>;
}) {
  const [balance, setBalance] = useState<string | undefined>();

  useEffect(() => {
    const setAccountBalance = async () => {
      const res = await props.manager.getWalletBalance(props.index);
      setBalance(res);
    };
    setAccountBalance();
  }, [props.manager.getWalletBalance(props.index)]);

  const handleAccountClick = async () => {
    props.setIdx(props.index);
    props.setAccount(props.walletItem.name);
    props.setDropdown(false);
  };

  return (
    <div
      onClick={handleAccountClick}
      className={
        "hover:bg-gray-600 hover:rounded-lg  px-4 py-2 cursor-pointer " +
        (props.isActive ? "bg-gray-600 rounded-lg" : "")
      }
    >
      <div className="font-medium ">{props.walletItem.name}</div>
      <div className="truncate font-light">{balance} ETH</div>
    </div>
  );
}

export default AccountItem;
