import { IStorage } from "../wallet-storage/type";
import { Buffer } from "buffer";
import { ethers } from "ethers";
import WalletAccount from "./wallet-account";

export const Errors = {
  wrongPassword: new Error("Yanlış parola."),
  emptyWalletData: new Error("Kayıt olmanız gerekiyor."),
  notMatchPassword: new Error("Parolalar aynı olmalı."),
  emptyPassword: new Error("Parola boş olmamalı."),
  emptyName: new Error("Hesap ismi boş olmamalı.")
};

export interface TransData {
  index: number;
  destIndex: number;
  to: string;
  from: string;
  contractAddress: string;
  gasUsed: string;
  blockHash: string;
  transactionHash: string;
  blockNumber: number;
  confirmations: number;
  effectiveGasPrice: string;
  amount: string;
}

class WalletManager {
  private wallets: Array<WalletAccount> = [];
  private Wallet: typeof WalletAccount;
  private password?: string;
  private storage: IStorage;
  private transactions: Array<TransData> = [];

  constructor(Wallet: typeof WalletAccount, storage: IStorage) {
    this.Wallet = Wallet;
    this.storage = storage;
  }

  getPassword() {
    return this.password;
  }

  setWalletPassword(password: string) {
    this.password = password;
  }

  getTransactions(index: number) {
    let indTransactions: Array<TransData> = [];
    let i;
    for (i = 0; i < this.transactions.length; i++) {
      if (index === this.transactions[i].index) {
        indTransactions.push(this.transactions[i]);
      } else if (index === this.transactions[i].destIndex) {
        indTransactions.push(this.transactions[i]);
      }
    }
    return indTransactions;
  }

  persistTransactions() {
    const transactions: Array<TransData> = this.storage.get(
      "trans"
    ) as Array<TransData>;

    if (transactions) {
      this.transactions = transactions;
    }
  }

  async create(isDropdown:boolean=false,name:string) {

    let wallet = new this.Wallet();
    if(!isDropdown){
      wallet.createMasterWallet(name);
    }else{
      //console.log(this.wallets[0])
      wallet.createChildWallet(this.wallets[0],this.wallets.length,name)
    }

    this.wallets.push(wallet);
    await this.encrypt(this.wallets.length - 1);

  }
  async import(mnemonicKey:string,name:string){
    let wallet = new this.Wallet();
    wallet.importMasterWallet(mnemonicKey,name);
    this.wallets = [];
    this.wallets.push(wallet);
    await this.encrypt(this.wallets.length - 1);

  }


  async decrypt(index: number) {
    const wallet = this.wallets[index];
    if (!wallet.isAlive() && this.password) {
      await wallet.decryptAccount(this.password);
    }
  }

  async encrypt(index: number) {
    const wallet = this.wallets[index];
    const tmpPassword = this.password; // Registerda oluşturulan wallet için şifre bilgisi parametre olarak verilmeli onun kontrolü

    if (wallet.isAlive() && tmpPassword) {
      await wallet.encryptAccount(tmpPassword);
    }
    await this.store();
  }

  async aesEncrypt(plaintext: string, key: CryptoKey) {
    const ec = new TextEncoder();
    const iv = Buffer.from(String(process.env.REACT_APP_SALT_KEY), "hex");

    const ciphertext = await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv,
      },
      key,
      ec.encode(plaintext)
    );

    return {
      key,
      iv,
      ciphertext,
    };
  }

  async aesDecrypt(ciphertext: ArrayBuffer, key: CryptoKey, iv: ArrayBuffer) {
    const dec = new TextDecoder();
    const plaintext = await window.crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv,
      },
      key,
      ciphertext
    );

    return dec.decode(plaintext);
  }

  async encryptPassword(password: string) {
    const key: string = String(process.env.REACT_APP_AES_KEY);
    const bufferKey: ArrayBuffer = Buffer.from(key, "hex");
    let cryptoObject = await window.crypto.subtle.importKey(
      "raw",
      bufferKey,
      "AES-GCM",
      true,
      ["encrypt", "decrypt"]
    );
    let encryptedPassword = await this.aesEncrypt(password, cryptoObject);
    let encryptedString = Buffer.from(encryptedPassword.ciphertext).toString(
      "hex"
    );
    sessionStorage.setItem("password", String(encryptedString));
  }

  async decryptPassword() {
    const encryptedString: string = String(sessionStorage.getItem("password"));
    const encryptedBuffer: ArrayBuffer = Buffer.from(encryptedString, "hex");
    const key: string = String(process.env.REACT_APP_AES_KEY);
    const bufferKey: ArrayBuffer = Buffer.from(key, "hex");
    let cryptoObject = await window.crypto.subtle.importKey(
      "raw",
      bufferKey,
      "AES-GCM",
      true,
      ["encrypt", "decrypt"]
    );
    const iv = Buffer.from(String(process.env.REACT_APP_SALT_KEY), "hex");
    const password = await this.aesDecrypt(encryptedBuffer, cryptoObject, iv);
    return password;
  }

  findDestIndex(address: string) {
    let i: number;
    for (i = 0; i < this.wallets.length; i++) {
      if (this.wallets[i].getAddress() === address) {
        return i;
      }
    }
    return -1;
  }

  async sendETH(index: number, dest: string, amount: string) {
    const wallet = this.wallets[index];
    console.log(dest);
    console.log(amount);
    let res = await wallet.sendETH(dest, amount);
    const transactions: Array<TransData> = this.storage.get(
      "trans"
    ) as Array<TransData>;

    const destIndex = this.findDestIndex(res.to);

    let transactionLog = {
      index: index,
      destIndex: destIndex,
      to: res.to,
      from: res.from,
      contractAddress: res.contractAddress,
      gasUsed: res.gasUsed._hex,
      blockHash: res.blockHash,
      transactionHash: res.transactionHash,
      blockNumber: res.blockNumber,
      confirmations: res.confirmations,
      effectiveGasPrice: res.effectiveGasPrice._hex,
      amount: amount,
    } as TransData;

    if (!transactions) {
      this.transactions.push(transactionLog);
      this.storage.set("trans", this.transactions);
    } else {
      this.transactions = transactions;
      this.transactions.push(transactionLog);
      this.storage.set("trans", this.transactions);
    }
  }

  async getMnemonicKey(index: number){
    const wallet = this.wallets[index];
    return wallet.getMnemonic();
  }

  async getPrivateKey(index: number) {
    const wallet = this.wallets[index];
    return wallet.getPrivateKey();
  }

  async getWalletBalance(index: number) {
    const wallet = this.wallets[index];
    return await wallet.getBalance();
  }

  private async store() {
    const data = this.wallets.map((wallet) => {
      return {
        name: wallet.getName(),
        address: wallet.getAddress(),
        encrypted: wallet.getEncrypted(),
      };
    });
    await this.storage.set("wallet", data);
  }

  loadFromStorage() {
    const data: { name: string, address: string; encrypted: string }[] = this.storage.get(
      "wallet"
    ) as { name: string, address: string; encrypted: string }[];

    if (data) {
      data.forEach((e) => {
        const wallet = new this.Wallet();
        wallet.loadJson(e.name,e.address, e.encrypted);
        this.wallets.push(wallet);
      });
    }

    return data || [];
  }

  async updatePassword() {
    try {
      const decrypted = await this.decryptPassword();
      this.password = decrypted;
    } catch (e) {
      console.log(e);
    }
  }

  async privateKeyEnsurePassword(
    password: string,
    index: number
  ): Promise<boolean> {
    let wallet = this.wallets[index];
    let encrypted = wallet.getEncrypted();
    try {
      await ethers.Wallet.fromEncryptedJson(encrypted!, password); // `wallet` state'ini mutate ediyor
      return true;
    } catch (error) {
      return false;
    }
  }

  async loginEnsurePassword(password: string): Promise<boolean> {
    if (this.wallets.length > 0) {
      let i;
      for (i = 0; i < this.wallets.length; i++) {
        let wallet = this.wallets[i];
        let encrypted = wallet.getEncrypted();
        try {
          await ethers.Wallet.fromEncryptedJson(encrypted!, password); // `wallet` state'ini mutate ediyor
          sessionStorage.setItem("token", "true");
          return true;
        } catch (error) {
          return false;
        }
      }
    }
    return true;
  }

  getData(): { name:string, address: string; encrypted: string }[] {
    return this.wallets.map((wallet) => {
      return {
        name: wallet.getName() || "",
        address: wallet.getAddress() || "",
        encrypted: wallet.getEncrypted() || "",
      };
    });
  }
}

export default WalletManager;
