import { useEffect, useState } from "react";
import getWalletManager from "../utils/wallet-manager-init";
import AccountItem from "./AccountItem";
import navLogo from "../assets/bag-nav.png";
import ethLogo from "../assets/ethereum-eth-logo.png";
import AccountDetails from "./AccountDetails";
import PrivateKeyModal from "./PrivateKeyModal";
import MnemonicKeyModal from "./MnemonicKeyModal";
import { setGlobalState, useGlobalState } from "../auth";
import SendModal from "./SendModal";
import TransactionHistory from "./TransactionHistory";
import { TransData } from "../lib/wallet-manager";
import TxLogModal from "./TxLogModal";
import WalletConnectModal from "./WalletConnectModal";
import SessionsModal from "./SessionsModal";
import PairingsModal from "./PairingsModal";
import DeletePairConfirm from "./DeletePairConfirm";
import NewAccountModal from "./NewAccountModal";
import WelcomeModal from "./WelcomeModal";

function Home() {
  const manager = getWalletManager();
  manager.updatePassword();
  const showWelcomeModal = useGlobalState("showWelcomeModal")[0];
  const [wallets, setWallets] = useState(manager.getData());
  const [dropdown, setDropdown] = useState(false);
  const [idx, setIdx] = useState(0);
  const [account, setAccount] = useState(wallets[idx].name);
  const [showPrivateModal, setShowPrivateModal] = useState(false);
  const [showMnemonicModal, setShowMnemonicModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showWcModal, setShowWcModal] = useState(false);
  const [showTxModal, setShowTxModal] = useState(false);
  const [showSessionsModal, setSessionsModal] = useState(false);
  const [showNewAccount, setShowNewAccountModal] = useState(false);
  const [showPairingsModal, setPairingsModal] = useState(false);
  const [transactions, setTransactions] = useState<Array<TransData>>(
    manager.getTransactions(idx)
  );
  const [transIdx, setTransIdx] = useState(0);
  const [dappName, setDappName] = useState<string | undefined>("");

  useEffect(() => {
    manager.persistTransactions();
    setTransactions(manager.getTransactions(idx));
  }, [idx]); //? React Hook useEffect has a missing dependency. Burada ne oluyor acaba?

  useEffect(() => {
    setGlobalState("isLogged", true);
  }, []);

  const handleCreateNewAccount = () => {
    setDropdown(false);
    setShowNewAccountModal(true);
  }

  const handleExportPrivateKey = async () => {
    setDropdown(false);
    setShowPrivateModal(true);
  };

  const handleExportMnemonicKey = async () => {
    setDropdown(false);
    setShowMnemonicModal(true);
  };

  const handleLogOut = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("password");
    setGlobalState("isLogged", false);
  }

  const handleGetSessions = () => {
    setDropdown(false);
    setSessionsModal(true);
  }

  const handleGetPairings = () => {
    setDropdown(false);
    setPairingsModal(true);
  }

  const handleOnClose = () => {
    setShowPrivateModal(false);
    setShowSendModal(false);
    setShowTxModal(false);
    setShowWcModal(false);
    setSessionsModal(false);
    setPairingsModal(false);
    setShowMnemonicModal(false);
    setShowNewAccountModal(false);
    setGlobalState("showWelcomeModal",false);
  };

  return (
    <div className="bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#24b7c3] to-teal-50 min-h-screen w-full items-center">
      <div
        className={
          "w-screen h-screen z-20 absolute " + (!dropdown ? "hidden" : "")
        }
        onClick={() => setDropdown(false)}
      ></div>
      <div className="h-screen flex flex-col items-center justify-center md:h-screen lg:py-0 md:py-0">
        <div className="flex md:w-[80%] w-[85%]">
          <img src={navLogo} className="w-68 h-16 mb-3" />
        </div>
        <div className="w-[85%] md:w-[80%] md:h-[75%] h-[80%] bg-gray-800 rounded-lg shadow-lg flex flex-col items-center">
          <div className="flex flex-col md:w-full md:flex-row">
            <div className="flex md:justify-start justify-center px-12 md:pb-4 md:pt-6 text-gray-200 font-semibold w-full md:text-sm text-xs">

            </div>
            <div className="md:w-full flex justify-end px-12 pt-4 text-white font-semibold">
              <div className="relative mx-auto sm:mx-0">
                <button
                  id="dropdownAvatarNameButton"
                  data-dropdown-toggle="dropdownAvatarName"
                  className="flex flex-row mb-4 items-center w-[138px] text-sm font-medium rounded-full hover:text-[#24b7c3] border-4 border-gray-700 md:mr-0 focus:ring-4 focus:ring-gray-700 text-white"
                  type="button"
                  onClick={() => setDropdown(!dropdown)}
                >
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="flex justify-start w-8 h-8 mr-2 rounded-full border-2 bg-white"
                    src={ethLogo}
                    alt="user photo"
                  />
                  <div className="flex w-full truncate justify-center text-center">
                    <p className="truncate">{account}</p>
                    <svg
                      className="w-4 h-4 mx-1.5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </button>
                <div
                  id="dropdownAvatarName"
                  className={
                    "z-30 divide-y rounded-lg shadow w-44 bg-gray-700 divide-gray-600 " +
                    (!dropdown ? "hidden" : "")
                  }
                  style={{
                    position: "absolute",
                    inset: "50px auto auto -18px",
                    margin: "0px",
                  }}
                >
                  <div className="text-sm text-white max-h-[10.5rem] overflow-auto">
                    {wallets.map((wallet, index) => {
                      return (
                        <AccountItem
                          key={wallet.address}
                          manager={manager}
                          index={index}
                          walletItem={wallet}
                          setAccount={setAccount}
                          setIdx={setIdx}
                          isActive={index === idx}
                          setDropdown={setDropdown}
                        ></AccountItem>
                      );
                    })}
                  </div>
                  <ul
                    className="py-2 text-sm text-gray-200"
                    aria-labelledby="dropdownInformdropdownAvatarNameButtonationButton"
                  >
                    <li>
                      <a
                        onClick={handleCreateNewAccount}
                        className="block px-4 py-2 hover:bg-gray-600 hover:text-white cursor-pointer"
                      >
                        + Yeni Hesap Ekle
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={handleExportPrivateKey}
                        className="block px-4 py-2 hover:bg-gray-600 hover:text-white cursor-pointer"
                      >
                        Özel Anahtarı Getir
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={handleExportMnemonicKey}
                        className="block px-4 py-2 hover:bg-gray-600 hover:text-white cursor-pointer"
                      >
                        Mnemonic'i Getir
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={handleGetSessions}
                        className="block px-4 py-2 hover:bg-gray-600 hover:text-white cursor-pointer"
                      >
                        Oturumlar
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={handleGetPairings}
                        className="block px-4 py-2 hover:bg-gray-600 hover:text-white cursor-pointer"
                      >
                        Eşleşmeler
                      </a>
                    </li>
                  </ul>
                  <div className="py-2">
                    <a
                      onClick={handleLogOut}
                      className="block px-4 py-2 text-sm hover:bg-gray-600 text-gray-200 hover:text-white cursor-pointer"
                    >
                      Oturumu Kapat
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row w-full h-full items-center gap-y-4 lg:gap-y-8 px-8 lg:px-16 pb-8 lg:pb-16 overflow-y-auto">
            <div className="h-1/2 lg:w-1/2 h-full flex items-center justify-center pt-2 lg:pt-0 lg:pr-12">
              <AccountDetails
                manager={manager}
                index={idx}
                walletItem={wallets[idx]}
                setShowSendModal={setShowSendModal}
                setShowWcModal={setShowWcModal}
              ></AccountDetails>
            </div>
            <span className="h-0.5 lg:w-0.5 bg-gray-600 w-full lg:h-full "></span>
            <div className="h-1/2 w-full lg:w-1/2 lg:h-full flex items-center justify-center lg:pl-16 lg:py-10">
              <TransactionHistory
                manager={manager}
                index={idx}
                transactions={transactions}
                setTransIdx={setTransIdx}
                setShowTxModal={setShowTxModal}
              ></TransactionHistory>
            </div>
          </div>
        </div>
      </div>
      {showPrivateModal ? <PrivateKeyModal
        onClose={handleOnClose}
        visible={showPrivateModal}
        index={idx}
        manager={manager}
        walletItem={wallets[idx]}
      /> : null}

      <MnemonicKeyModal
        onClose={handleOnClose}
        visible={showMnemonicModal}
        index={idx}
        manager={manager}
      />

      <SendModal
        onClose={handleOnClose}
        visible={showSendModal}
        index={idx}
        manager={manager}
        setWallets={setWallets}
        setTransactions={setTransactions}
      />

      <TxLogModal
        onClose={handleOnClose}
        visible={showTxModal}
        transactions={transactions}
        transIdx={transIdx}
      />

      <WelcomeModal
        onClose={handleOnClose}
        visible={showWelcomeModal}
        manager={manager}
        index={0}
        walletItem={wallets[0]}
      />

      {showNewAccount ? <NewAccountModal
        onClose={handleOnClose}
        visible={showNewAccount}
        manager={manager}
        setWallets={setWallets}
        wallets={wallets}
        index={idx}
      /> : null}

      {showWcModal ? <WalletConnectModal
        onClose={handleOnClose}
        visible={showWcModal}
        setDappName={setDappName}
      /> : null}

      {showSessionsModal ? <SessionsModal
        onClose={handleOnClose}
        visible={showSessionsModal}
      /> : null}

      {showPairingsModal ? <PairingsModal
        onClose={handleOnClose}
        visible={showPairingsModal}
      /> : null}
    </div>
  );
}

export default Home;
