import { Dispatch, SetStateAction, useEffect, useState } from "react";
import WalletManager from "../lib/wallet-manager";
import { TransData } from "../lib/wallet-manager";

function TransactionHistory(props: {
  manager: WalletManager;
  index: number;
  transactions: Array<TransData>;
  setTransIdx: Dispatch<SetStateAction<number>>;
  setShowTxModal: Dispatch<SetStateAction<boolean>>;
}) {
  let address: string;
  let log: string;
  let color: boolean;
  let shortAdr: string;
  const [isEmpty, setIsEmpty] = useState<boolean>();

  const handleTransClick = (idx: number) => {
    props.setTransIdx(idx);
    props.setShowTxModal(true);
  };

  useEffect(() => {
    if (props.transactions.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [props]);

  //console.log(isEmpty);

  return (
    <div className="flex flex-col w-full h-full text-center gap-y-6">
      <span className="text-white font-semibold">İşlem Geçmişi</span>
      <div className="bg-gradient-to-r from-[#24b7c3] to-cyan-200 w-full h-3/5 md:h-4/5 lg:h-[81.7%] rounded-lg overflow-y-auto shadow-2xl shadow-[#24b7c3]/40">
        <div className="max-w-full px-4 pt-4">
          {!isEmpty ? (
            [...props.transactions].reverse().map((transaction, index) => {
              if (props.index === transaction.index) {
                address = transaction.to;
                shortAdr =
                  address.slice(0, 8) +
                  "....." +
                  address.slice(address.length - 6, address.length) +
                  " adresine";
                log = "-" + transaction.amount + " ETH";
                color = false;
              } else if (props.index === transaction.destIndex) {
                address = transaction.from;
                shortAdr =
                  address.slice(0, 8) +
                  "....." +
                  address.slice(address.length - 6, address.length) +
                  " adresinden";
                log = "+" + transaction.amount + " ETH";
                color = true;
              }
              return (
                <div className="pb-4">
                  <div
                    onClick={() => handleTransClick(index)}
                    className="flex items-center space-x-4 bg-gray-800 py-2 md:py-4 text-xs md:text-sm px-4 rounded-lg break-words hover:bg-gray-700 cursor-pointer"
                  >
                    <div className="flex-1 min-w-0 text-left">
                      <p className="text-sm font-medium text-white truncate">
                        {!color ? (
                          <span className="font-extrabold text-red-500">
                            Gönderildi
                          </span>
                        ) : (
                          <span className="font-extrabold text-green-500">
                            Alındı
                          </span>
                        )}
                      </p>
                      <p className="text-sm text-gray-400 truncate">
                        {shortAdr}
                      </p>
                    </div>
                    <div className="inline-flex items-center text-base font-semibold">
                      {!color ? (
                        <span className="font-extrabold text-red-500">
                          {log}
                        </span>
                      ) : (
                        <span className="font-extrabold text-green-500">
                          {log}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <span className="text-gray-800 font-bold text-center text-lg items-center">
              Herhangi bir işlem kaydınız bulunmuyor.
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default TransactionHistory;
