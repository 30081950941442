import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MdContentCopy, MdDone } from "react-icons/md";
import WalletManager, { Errors } from "../lib/wallet-manager";
import { MnemonicKeyPage } from "./MnemonicKeyModal";
import { PrivateKeyPage } from "./PrivateKeyModal";
import { WalletData } from "../utils/wallet-manager-init";


export default function WelcomeModal(props: {
    visible: boolean;
    onClose: any;
    manager: WalletManager;
    index: number;
    walletItem: WalletData
}) {
    const [error, setError] = useState();
    const [redirect, setRedirect] = useState(true);

    const handleRedirect = () => {
        setRedirect(false);
    }

    if (!props.visible) return null;

    return (
        <div className="fixed inset-0 bg-opacity-30 backdrop-blur-sm flex justify-center items-center p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
            <div className="relative w-full max-w-md h-auto bg-gray-700 rounded-lg shadow-2xl shadow-[#24b7c3]/40">
                <div className="flex flex-col">
                    <h3 className=" text-transparent bg-clip-text bg-gradient-to-r from-cyan-600 to-teal-100 text-center pt-6 font-extrabold text-3xl px-3">Bağ Dijital Cüzdanınıza Hoş Geldiniz!</h3>
                    {redirect ?
                        <>
                            <MnemonicKeyPage manager={props.manager} index={props.index} />
                            <div className="flex items-center pb-4 pt-1 space-x-2 border-gray-600 rounded-b justify-center mx-6">
                                <button
                                    onClick={handleRedirect}
                                    data-modal-hide="small-modal"
                                    type="button"
                                    className="text-white w-full focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-[#24b7c3] hover:bg-[#10767f]"
                                >
                                    Devam
                                </button>
                            </div> </> :
                        <>
                            <PrivateKeyPage manager={props.manager} index={props.index} walletItem={props.walletItem} />
                            <div className="flex items-center pb-4 pt-1 space-x-2 border-gray-600 rounded-b justify-center mx-6">
                                <button
                                onClick={props.onClose}
                                    data-modal-hide="small-modal"
                                    type="button"
                                    className="text-white w-full focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-[#24b7c3] hover:bg-[#10767f]"
                                >
                                    Kullanmaya Başla
                                </button>
                            </div>
                        </>
                    }

                </div>
            </div>
        </div>
    );
}