import { ethers } from "ethers";
import { JsonRpcProvider } from "@ethersproject/providers";
// import { IStorage } from "../wallet-storage/type";
// import { TransData } from "./wallet-manager";

class WalletAccount {
  private name: string ="";
  private wallet: ethers.Wallet | undefined;
  private address: string = "";
  private encrypted: string | undefined;
  private provider: JsonRpcProvider;
  private mnemonic: string | undefined;
  private path: string | undefined;

  constructor() {
    this.provider = new JsonRpcProvider("https://rpc.eth.bag.org.tr");
  }

  createMasterWallet(name:string) {
    this.name = name;
    this.wallet = ethers.Wallet.createRandom();
    this.address = this.wallet.address;
    this.mnemonic = this.wallet.mnemonic.phrase;
    this.path = this.wallet.mnemonic.path;
  }

  importMasterWallet(mnemonicKey:string,name:string) {
    this.name = name;
    this.wallet = ethers.Wallet.fromMnemonic(mnemonicKey);
    this.address = this.wallet.address;
    this.mnemonic = this.wallet.mnemonic.phrase;
    this.path = this.wallet.mnemonic.path;
  }
  
  createChildWallet(masterWallet:WalletAccount,index:number,name:string){
    let path = masterWallet.path!;
    let pathArray = path.split("/");
    pathArray.pop();
    pathArray.push(String(index));
    path =pathArray.join("/");
    console.log(path);
    console.log(masterWallet.mnemonic!);
    this.name = name;
    this.wallet = ethers.Wallet.fromMnemonic(masterWallet.mnemonic!,path);
    this.address = this.wallet.address;
    this.mnemonic = this.wallet.mnemonic.phrase;
    this.path = this.wallet.mnemonic.path;
  }

  async encryptAccount(password: string) {
    if (this.isAlive() && !this.encrypted) {
      let options = {
        scrypt: {
          N: 1 << 1,
        },
      };
      this.encrypted = await this.wallet?.encrypt(password, options);
    }
  }

  getName(): string {
    return this.name;
  }

  getAddress(): string {
    return this.address;
  }
  getEncrypted(): string | undefined {
    if (this.encrypted) {
      return this.encrypted;
    }
  }

  isAlive(): boolean {
    return this.wallet !== null && this.wallet !== undefined;
  }
  getPrivateKey(): string | undefined {
    if (this.isAlive()) {
      return this.wallet?.privateKey;
    }
  }

  getMnemonic(): string | undefined {
    if (this.isAlive()) {
      return this.mnemonic;
    }
  }

  async sendETH(dest: string, amount: string) {
    const gasPrice = this.provider.getGasPrice();
    console.log("Gonderen adresi: " + this.address);
    console.log(ethers.utils.parseEther(amount).toString());
    console.log(this.wallet);
    const signer = this.wallet!.connect(this.provider);
    const tx = {
      from: this.wallet?.address,
      to: dest,
      value: ethers.utils.parseEther(amount),
      gasPrice: gasPrice,
      gasLimit: ethers.utils.hexlify(100000),
      nonce: this.provider.getTransactionCount(this.wallet?.address!, "latest"),
    };

    const transaction = await signer!.sendTransaction(tx);
    console.log("Transaction onayı bekleniyor...");
    const receipt = await transaction.wait(1);
    return receipt;
  }

  async getBalance(): Promise<string | undefined> {
    const balance = await this.provider.getBalance(this.address);
    const balanceInEth = ethers.utils.formatEther(balance);
    return balanceInEth;
  }
  async decryptAccount(password: string, force?: boolean) {
    if ((force || !this.isAlive()) && this.encrypted) {
      this.wallet = await ethers.Wallet.fromEncryptedJson(
        this.encrypted,
        password
      );
      this.mnemonic = this.wallet.mnemonic.phrase;
      this.path = this.wallet.mnemonic.path;
    }
  }

  loadJson(name:string, address: string, json: string) {
    this.name = name;
    this.address = address;
    this.encrypted = json;
  }
}

export default WalletAccount;
