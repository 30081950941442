import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { WCFacade } from "../lib/walletconnect-utils";
import { getSdkError } from '@walletconnect/utils';
import wcLogo from "../assets/wc2-logo.svg";
import DeletePairConfirm from "./DeletePairConfirm";
import { RiDeleteBin5Fill } from "react-icons/ri";

export default function SessionsModal(props: {
    visible: boolean;
    onClose: any;
}) {
    let [sessions, setSessions] = useState(WCFacade.signClient?.session.values);
    const [showDeleteModal, setDeleteModal] = useState(false);


    const handleOnDelete = async (topic: string) => {
        await WCFacade.signClient?.disconnect({ topic, reason: getSdkError('USER_DISCONNECTED') })
        const newSessions = sessions!.filter(session => session.topic !== topic)
        setSessions(newSessions)
    }

    const handleOnClose = () => {
        setDeleteModal(false);
    }




    if (!props.visible) return null;

    return (
        <div className="fixed inset-0 bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
            <div className="relative flex justify-center items-center w-full max-w-md h-1/2 mx-4 md:mx-0">
                <div className="flex flex-col justify-center items-center w-full h-full text-center gap-y-6 ">
                    <div className="relative bg-gray-900 w-full h-[72%] md:h-3/5 lg:h-[95%] rounded-lg shadow-2xl shadow-[#24b7c3]/40 overflow-hidden">
                        <div className="flex items-center justify-between px-5 py-4 border-gray-500">
                            <div className="flex-shrink-0">
                                <img className="w-10" src={wcLogo} alt="" />
                            </div>
                            <h3 className="text-xl font-medium text-white">Güncel Oturumlar</h3>
                            <button
                                type="button"
                                onClick={() => {
                                    props.onClose();
                                }}
                                className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
                                data-modal-hide="small-modal"
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="bg-gray-900 to-cyan-200 w-full h-full rounded-lg overflow-y-auto">
                            <div className="max-w-full px-4 pt-4">
                                {sessions?.length ? (
                                    sessions.map((session: any, index: number) => {

                                        return (
                                            <div className="pb-4">
                                                <div
                                                    className="flex items-center space-x-4 bg-gray-900 py-2 md:py-4 text-xs md:text-sm px-4 rounded-lg break-words border-2 border-[#24b7c3]"
                                                >
                                                    <div className="flex-shrink-0">
                                                        <img className="w-8" src={session.peer.metadata.icons[0]} alt="" />
                                                    </div>
                                                    <div className="flex-1 min-w-0 text-left">
                                                        <p className="text-sm font-medium text-white truncate">
                                                            {session.peer.metadata.name}
                                                        </p>
                                                        <a href={session.peer.metadata.url} target="_blank">
                                                            <p className="text-sm text-gray-400 truncate">
                                                                <span className="hover:text-[#24b7c3] cursor-pointer">{session.peer.metadata.url}</span>
                                                            </p>
                                                        </a>
                                                    </div>
                                                    <div onClick={() => {
                                                        setDeleteModal(true);
                                                    }}
                                                        className="inline-flex items-center text-base font-semibold border-2 border-red-700 p-1.5 rounded-lg hover:bg-red-900 hover:text-gray-900 cursor-pointer text-red-700">
                                                        <RiDeleteBin5Fill size="1.1rem" />
                                                    </div>
                                                </div>
                                                <DeletePairConfirm
                                                    onClose={handleOnClose}
                                                    visible={showDeleteModal}
                                                    message="Oturumu silmek istediğinizden emin misiniz?"
                                                    onDelete={() => handleOnDelete(session.topic)}
                                                />
                                            </div>
                                        );

                                    })
                                ) : (
                                    <span className="text-gray-200 font-bold text-center text-lg items-center">
                                        Herhangi bir oturuma bağlı değilsiniz.
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}