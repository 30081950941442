import React, { Dispatch, SetStateAction, useState } from "react";
import WalletManager, { TransData } from "../lib/wallet-manager";
import ReactLoading from "react-loading";

export default function SendModal(props: {
  visible: boolean;
  index: number;
  manager: WalletManager;
  onClose: any;
  setWallets: Dispatch<
    SetStateAction<{ name:string, address: string; encrypted: string }[]>
  >;
  setTransactions: Dispatch<SetStateAction<Array<TransData>>>;
}) {
  const [destAddress, setSendAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [confirmFlag, setConfirmFlag] = useState(false);
  props.manager.updatePassword();

  const initTransaction = async () => {
    setLoadingFlag(true);

    try {
      await props.manager.decrypt(props.index);
      await props.manager.sendETH(props.index, destAddress, amount);
      setLoadingFlag(false);
      setConfirmFlag(true);

      setTimeout(function () {
        setConfirmFlag(false);
      }, 3000);

      props.setWallets(props.manager.getData());
      props.setTransactions(props.manager.getTransactions(props.index));
    } catch (err) {
      console.log(err);
      setLoadingFlag(false);
      setErrorFlag(true);

      setTimeout(function () {
        setErrorFlag(false);
      }, 3000);
    }
  };

  if (!props.visible) return null;

  return (
    <div className="fixed inset-0 bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className="relative flex flex-col justify-center items-center w-full max-w-md h-full mx-4 md:mx-0">
        <div className="relative bg-gray-700 rounded-lg shadow-2xl shadow-[#24b7c3]/40 w-full">
          <div className="flex items-center justify-between p-5 border-gray-500 rounded-t">
            <h3 className="text-xl font-medium text-white">BAGETH Transfer</h3>
            <button
              type="button"
              onClick={() => {
                props.onClose();
              }}
              className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
              data-modal-hide="small-modal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div>
            <div className="p-6 space-y-6">
              <label
                htmlFor="password"
                className="block mb-2 text-md font-medium text-white"
              >
                Alıcı Adres
              </label>
              <input
                type="text"
                className="bg-gray-600 border border-gray-600 text-white gsm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                onChange={(e) => {
                  setSendAddress(e.target.value);
                }}
              ></input>
              <label
                htmlFor="password"
                className="block mb-2 text-md font-medium text-white"
              >
                Miktar
              </label>
              <input
                type="text"
                className="bg-gray-600 border border-gray-600 text-white gsm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                maxLength={32}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              ></input>
            </div>
            <div className="flex items-center p-6 space-x-2 border-t border-gray-600 rounded-b justify-center">
              <button
                onClick={async () => await initTransaction()}
                data-modal-hide="small-modal"
                type="button"
                className="text-white focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-[#24b7c3] hover:bg-[#10767f]"
              >
                GÖNDER
              </button>
            </div>
          </div>
        </div>

        {loadingFlag ? (
          <div className="w-full mt-2 flex items-center justify-center gap-x-4 bg-blue-600 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md">
            <ReactLoading
              className="text-white text-center my-auto md:mt-1"
              type="spin"
              height="16px"
              width="16px"
            />
            <span>İşlem gerçekleşiyor...</span>
          </div>
        ) : null}
        {errorFlag ? (
          <div className="w-full mt-2 flex items-center justify-center bg-red-600 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md">
            {" "}
            İşlem sırasında sorun oluştu.
          </div>
        ) : null}
        {confirmFlag ? (
          <div className="w-full mt-2 flex items-center justify-center bg-green-600 text-xs md:text-base p-3 md:p-4 text-white font-semibold shadow-md rounded-md">
            {" "}
            İşlem başarıyla gerçekleşti{" "}
          </div>
        ) : null}
      </div>
    </div>
  );
}
