import { Dispatch, SetStateAction, useEffect, useState } from "react";
import WalletManager from "../lib/wallet-manager";
import { WalletData } from "../utils/wallet-manager-init";
import { MdContentCopy, MdDone } from "react-icons/md";
import { IoSendSharp } from "react-icons/io5";

function AccountDetails(props: {
  index: number;
  walletItem: WalletData;
  manager: WalletManager;
  setShowSendModal: Dispatch<SetStateAction<boolean>>;
  setShowWcModal: Dispatch<SetStateAction<boolean>>;
}) {
  const [shortAddress, setShortAddress] = useState("");
  const [infoCopy, setInfoCopy] = useState(false);
  const [tick, setTick] = useState(false);
  const [balance, setBalance] = useState<string | undefined>("");
  useEffect(() => {
    const setAccountBalance = async () => {
      const res = await props.manager.getWalletBalance(props.index);
      setBalance(res);
    };
    let address = props.walletItem.address;
    let shortFormat =
      address.slice(0, 8) +
      "....." +
      address.slice(address.length - 6, address.length);
    setShortAddress(shortFormat);
    setAccountBalance();
  }, [props.manager.getWalletBalance(props.index)]);

  return (
    <div className="space-y-8 md:space-y-12 lg:space-y-20">
      <div
        onClick={() => {
          navigator.clipboard.writeText(props.walletItem.address);
          setTick(true);
          setTimeout(function () {
            setTick(false);
          }, 4000);
        }}
        onMouseOver={() => setInfoCopy(true)}
        onMouseOut={() => setInfoCopy(false)}
        className="relative items-center justify-center text-sm text-white font-light text-base hidden md:hidden lg:flex transition-colors cursor-pointer gap-x-4 bg-gray-700 py-4 px-8 rounded-full hover:bg-gray-600 address-bar"
      >
        <h3>{props.walletItem.address} </h3>
        {!tick && <MdContentCopy size={'1.1rem'}/>}

        {tick && <MdDone size={'1.1rem'} className="text-green-400" />}

        {infoCopy && (
          <span
            className={
              "absolute -right-5 -bottom-1/2 text-xs bg-gray-900 px-4 py-2 rounded-md cursor-default"
            }
          >
            Adresi Kopyala
          </span>
        )}
      </div>
      <div
        onClick={() => {
          navigator.clipboard.writeText(props.walletItem.address);
          setTick(true);
          setTimeout(function () {
            setTick(false);
          }, 4000);
        }}
        onMouseOver={() => setInfoCopy(true)}
        onMouseOut={() => setInfoCopy(false)}
        className="items-center justify-center text-white font-light text-base flex lg:hidden transition-colors cursor-pointer gap-x-4 bg-gray-700 py-3 rounded-full hover:bg-gray-600 short-address"
      >
        <h3>{shortAddress}</h3>
        {!tick && <MdContentCopy/>}

        {tick && <MdDone className="text-green-400" />}
      </div>
      <div className="text-white text-3xl md:text-5xl text-center">
        {balance} ETH
      </div>

      <div className="flex justify-center space-x-6">
        <button
          onClick={() => {
            props.setShowWcModal(true);
          }}
          type="button"
          className="inline-block rounded-full bg-info px-4 lg:px-6 pt-2.5 pb-2 text-sm font-medium md:text-sm text-xs leading-normal text-white bg-[#24b7c3]  hover:bg-[#10767f] shadow-lg shadow-[#24b7c3]/50 hover:shadow-[#10767f]/80"
        >
          WalletConnect
        </button>

        <button
          onClick={() => {
            props.setShowSendModal(true);
          }}
          type="button"
          className="inline-block flex flex-row space-x-2 rounded-full px-4 lg:px-6 pt-2.5 pb-2 md:text-sm text-xs font-medium uppercase leading-normal text-white bg-[#24b7c3] hover:bg-[#10767f]  shadow-lg shadow-[#24b7c3]/50 hover:shadow-[#10767f]/80"
        >
          <span>GÖNDER</span>
          <IoSendSharp size="1.0rem" />
        </button>
      </div>
    </div>
  );
}

export default AccountDetails;
