import React, { useState } from "react";
import navLogo from "../assets/bag-nav.png";
import { Errors } from "../lib/wallet-manager";
import getWalletManager from "../utils/wallet-manager-init";
import { setGlobalState } from "../auth";

function Register() {
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [accountName, setAccountName] = useState("");
  const [error, setError] = useState("");
  const manager = getWalletManager();

  const handleSubmit = async () => {
    if (password === "") {
      setError(Errors.emptyPassword.message);
      throw Errors.emptyPassword;
    }

    try {
      if (password === rePassword && accountName !== "") {
        await manager.encryptPassword(password);
        manager.setWalletPassword(password);
        await manager.create(undefined,accountName);
        setGlobalState("isLogged", true);
        sessionStorage.setItem("token", "true");
        setGlobalState("showWelcomeModal",true);
      } else if(password !== rePassword){
        throw Errors.notMatchPassword;
      }else if(accountName === ""){
        throw Errors.emptyName;
      }
    } catch (e: any) {
      console.log(e);
      setError(e.message);
    }
  };

  return (
    <div className="bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-rose-100 to-teal-100 min-h-screen w-full items-center">
      <div className="h-screen flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="https://blokzincir.bilgem.tubitak.gov.tr/"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
          target="_blank"
        >
          <img src={navLogo} className="w-68 h-20 mr-4" alt="bag-logo" />
        </a>
        <div className="w-full bg-gray-800 rounded-lg shadow-lg md:mt-0 sm:max-w-lg xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-white md:text-2xl text-center">
              Bağ Dijital Cüzdanınızı Oluşturun
            </h1>

            <p className="text-center text-sm text-white">Cüzdanınıza bir parola belirleyip ilk hesabınıza isim verin ya da var olan cüzdanınızı içeri aktarın!</p>

            {error ? <span
              className={
                !error
                  ? "invisible"
                  : "text-center text-red-500 font-semibold text-white flex items-center justify-center"
              }
            >
              {error || "Dumppy"}
            </span> : null}
            <div className="space-y-4 md:space-y-6">
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-md font-medium text-white"
                >
                  Parola
                </label>
                <input
                  type="password"
                  className="bg-gray-700 border border-gray-600 text-white sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  maxLength={32}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-md font-medium text-white dark:text-white"
                >
                  Parola Doğrula
                </label>
                <input
                  type="password"
                  className="bg-gray-700 border border-gray-600 text-white sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  maxLength={32}
                  onChange={(e) => {
                    setRePassword(e.target.value);
                  }}
                />
              </div>

              <div>
                <label
                  htmlFor="text"
                  className="block mb-2 text-md font-medium text-white dark:text-white"
                >
                  İlk Hesabınızın İsmi
                </label>
                <input
                  type="text"
                  className="bg-gray-700 border border-gray-600 text-white sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  maxLength={12}
                  onChange={(e) => {
                    setAccountName(e.target.value);
                  }}
                />
              </div>
              <div className="flex items-center justify-between">
                <a
                  href="/import"
                  className="text-md font-medium underline text-white hover:text-gray-300"
                >
                  Cüzdanı içe aktar
                </a>
              </div>

              <button
                type="submit"
                onClick={async () => {
                  await handleSubmit();
                }}
                className="hover:bg-[#10767f] w-full bg-[#24b7c3] text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-md px-5 py-2.5 text-center"
              >
                Kilidi Çöz
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
